import useGoBeWebRTC from 'GoBeWebRTC';
import {
	DataChannels,
	GobeWebRTCSessionConfiguration,
	PeerConnectionEndReasonCode,
	PilotPrimaryCamera,
	RobotNavCamera,
	SessionType,
} from 'GoBeWebRTC/types';
import { setParameter } from 'actions/setParam';
import {
	SET_AUTO_PARK_ENABLED,
	SET_CAMERA_CALIBRATION_DEVIATION_STEP,
	SET_CAMERA_CALIBRATION_TOOL_ENABLED,
	SET_DATA_CHANNEL,
	SET_DRAG_MODE,
	SET_FULL_SCREEN_STATUS,
	SET_NAV_SPEED,
	SET_ROBOT_INFO,
	SET_ROBOT_STATUS,
	SET_iS_ABRUPTLY_CLOSING,
} from 'actions/types';
import goFullscreenIcon from 'images/black-full-screen.svg';
import exitFullscreenIcon from 'images/exit-fullscreen.svg';
import settingsIcon from 'images/settings-outline.svg';
import EndSession from 'pages/endSession';
import React, {
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useIntl } from 'react-intl';
import { ConnectedProps, connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { AppRootState } from 'reducers';
import { closeFullScreen, openFullscreen } from 'utils/fullScreen';
import './index.scss';
import { translationWithErrorHandling } from '../../translation/handleMissingTranslation';

import RobotName from 'components/robotName';
import SessionID from 'components/sessionID';
import { AppContext } from 'context/appContext';
import {
	SettingHeaders,
	SettingPageHeaders,
	SettingPageSectionHeaders,
	SettingSectionHeaders,
	SettingTabHeaders,
} from 'hooks/useSettingsController';
import { LocalSessionInfo, RobotPrimaryCamera } from 'types';
import { WebRTCSessionConfiguration } from 'webRTC/types';
import './index.scss';
import ActiveNavigationInputIndicator from './indicators/activeNavigationInput';
import ImpairedDrivingIndicator from './indicators/drivingImpairment';
import FeedbackNotificationBar from './indicators/feedbackNotificationBar';
import PeerConnectionStatusIndicator from './indicators/peerConnectionStatus';
import AutoDockingInput from './navigation/autoDocking';
import KeyboardNavInput from './navigation/keyboard';
import useSessionNavController from './navigation/useSessionNavController';
import NavViewWithSessionOptions from './navigation/view';
import RobotUnavailableOverlay from './overlays/failedInitPeerConnection';
import SessionNetworkFailureOverlay from './overlays/failedSessionPeerConnection';
import MediaAccessErrorOverlay from './overlays/mediaDevicesAccessDenied';
import RetryingSessionOverlay from './overlays/retryingSession';
import PauseOrEndSessionOverlay from './overlays/sessionEndPause';
import SessionEndWarning from './overlays/sessionEndWarning';
import useSessionOverlay from './overlays/useSessionOverlay';
import StartingSessionOverlay from './overlays/startingSession';
import LocalVideo from './videos/localVideo';
import RemotePrimaryCamVideo from './videos/remoteVideo';
import UrlShare from './urlShare';
import Snackbar from 'components/snackbar';
import genericAlertIcon from 'images/alert.svg';

const SESSION_NETWORK_FAILURE_TIMEOUT = 60 * 1000;
const DEFAULT_ZOOM_CAM_CROPPING = { top: 0, bottom: 0, left: 0, right: 0 };

const reduxConnector = connect(
	(state: AppRootState) => ({
		sessionStartDate: state.sessionState.sessionStartDate,
		fullScreenStatus: state.sessionState.fullScreenStatus,
		navSpeed: state.sessionState.navSpeed,
		autoParkEnabled: state.sessionState.autoParkEnabled,
		dockControllerStatus: state.sessionState.dockControllerStatus,
		cameraCalibrationToolEnabled: state.sessionState.cameraCalibrationToolEnabled,
	}),
	{ setParameter }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;

const Session: React.FC<PropsFromRedux> = ({
	sessionStartDate,
	fullScreenStatus,
	navSpeed,
	setParameter,
	autoParkEnabled,
	dockControllerStatus,
	cameraCalibrationToolEnabled,
}) => {
	const intl = useIntl();
	const [searchParams] = useSearchParams();
	const { feedbackController, settingsController } = useContext(AppContext);
	const [reservationSessionEnded, setReservationSessionEnded] = useState(false);
	const sessionInfo = useMemo<LocalSessionInfo>(() => {
		const decodedSessionInfo = decodeURIComponent(atob(searchParams.get('sessionInfo') as string));
		const parsedSessionInfo = JSON.parse(decodedSessionInfo);
		// If robot does not support super zoom, the setting for it should be toggled off
		// and user should be barred from making changes
		(window as any).superZoomSupported = parsedSessionInfo.capabilities.super_zoom;
		if (!parsedSessionInfo.capabilities.super_zoom) {
			setTimeout(() => {
				// This has to be triggered after all routes have completed their render step
				// React complains otherwise as updates to settings trigger re-renders of route parent component
				settingsController.setSettingValue(SettingHeaders.ENABLE_SUPER_ZOOM, false);
			}, 5000);
		}
		return parsedSessionInfo;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	let sessionType: SessionType = 'normal';
	if (sessionInfo.reservation) {
		if (sessionInfo.reservation.currentReservation) {
			if (new Date(sessionInfo.reservation.currentReservation.startDate).getTime() < Date.now()) {
				sessionType = 'reservation';
			}
		}
	}
	const gobeWebRTCSessionConfiguration: GobeWebRTCSessionConfiguration = {
		webRTCSessionConfiguration: {
			id: sessionInfo.awsClientConfiguration.id,
			pilotId: sessionInfo.pilotId,
			clientId: sessionInfo.robot.id,
			role: 'VIEWER',
			region: sessionInfo.awsClientConfiguration.region,
			credentials: sessionInfo.awsClientConfiguration,
			iceTransportPolicy: sessionInfo.awsClientConfiguration.iceTransportPolicy,
			iceTransportProtocol:
				sessionInfo.awsClientConfiguration.iceTransportProtocolPilot ??
				sessionInfo.awsClientConfiguration.iceTransportProtocol ??
				'udp',
			preferredDevices: sessionInfo.devices,
		} as WebRTCSessionConfiguration,
		sessionType: sessionType,
		robotStatus: sessionInfo.robotStatus!,
		connectionsQuality: sessionInfo.connectionsQuality!,
		cloudLogLevel: sessionInfo.cloudLogLevel,
		syncStreamDisplay: sessionInfo.capabilities?.syncStreamDisplay,
	};

	const {
		pilotMedia,
		robotMedia,
		dataChannels,
		robotStatus,
		connectionsQuality,
		sessionState,
		isSessionPaused: sessionPaused,
		primaryCameraState,
		pivotState,
		toggleSuperZoom,
		togglePause,
		pivot,
		setLocalDisplays,
		shareUrl,
		reportWebRTCEvent,
		reportWebRTCUserRating,
		start,
		end,
		isStreamSynced,
		stats,
	} = useGoBeWebRTC(gobeWebRTCSessionConfiguration);

	const onHangupTimeout = (timeout: number) => {
		dataChannels[DataChannels.CONTROL_DATACHANNEL].send(
			JSON.stringify({
				type: 'hang_up_timeout',
				timeoutInMs: timeout,
			})
		);
	};

	const reportUserRating = useCallback(
		(rating, review) => {
			reportWebRTCUserRating(rating, review);
			reportWebRTCEvent({
				type: 'global',
				name: 'userRating',
				parameters: {
					rating,
					review,
				},
			});
		},
		[reportWebRTCEvent, reportWebRTCUserRating]
	);

	const closeSession = useCallback(
		(reason: PeerConnectionEndReasonCode) => {
			// Handle local hangup feedback prompt if enabled and session is older than 5 seconds
			if (
				feedbackController.isEnabled() &&
				feedbackController.isFeedbackableEnabled('sessionEnd') &&
				sessionStartDate.getTime() + 5000 <= +new Date()
			) {
				end(reason);
				feedbackController.prompt('sessionEnd', reportUserRating);
			} else {
				end(reason, () => {
					try {
						window.opener?.postMessage({ refreshPage: true }, document.referrer);
					} catch (e) {
						console.error(e);
					}
					window.close();
				});
			}
		},
		[end, feedbackController, reportUserRating, sessionStartDate]
	);

	useEffect(() => {
		setParameter(
			'autoParkEnabled',
			SET_AUTO_PARK_ENABLED,
			'autoParkEnabled' in sessionInfo.capabilities
				? sessionInfo.capabilities.autoParkEnabled
				: true
		);
		setParameter('robot', SET_ROBOT_INFO, sessionInfo.robot);
		document.title = `GoBe - ${sessionInfo.robot.name}`;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setParameter('robotStatus', SET_ROBOT_STATUS, robotStatus);
	}, [robotStatus, setParameter]);

	useEffect(() => {
		if (dataChannels[DataChannels.CONTROL_DATACHANNEL]) {
			setParameter(
				'controlDataChannel',
				SET_DATA_CHANNEL,
				dataChannels[DataChannels.CONTROL_DATACHANNEL]
			);
		}
	}, [dataChannels, setParameter]);

	useEffect(() => {
		if (isStreamSynced && dataChannels[DataChannels.CONTROL_DATACHANNEL]?.readyState === 'open') {
			let localVoiceVolume =
				settingsController.settings[SettingPageSectionHeaders.ADMIN].children[
					SettingPageHeaders.MISC
				].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.MISC].children[
					SettingHeaders.LOCAL_VOICE_VOLUME
				].value;
			dataChannels[DataChannels.CONTROL_DATACHANNEL].send(
				`${translationWithErrorHandling(intl, 'session.vol')} ${localVoiceVolume}`
			);
			dataChannels[DataChannels.CONTROL_DATACHANNEL].send(
				JSON.stringify({ type: 'change_volume', volume: localVoiceVolume })
			);
		}
	}, [dataChannels, isStreamSynced]);

	const [hasPrimaryVideoStartedPlaying, setHasPrimaryVideoStartedPlaying] = useState(false);

	const { currentOverlay, showOverlay, hideOverlay } = useSessionOverlay();

	const primaryCamerasConfig = useMemo((): React.ComponentProps<
		typeof RemotePrimaryCamVideo
	>['cameraConfigs'] => {
		return {
			[RobotPrimaryCamera.WIDE_CAM]: {
				rotationDegrees: sessionInfo.capabilities.wide_camera_rotation,
				crop: sessionInfo.capabilities.wide_camera_crop,
				deviation: sessionInfo.capabilities.wide_cam_deviation_percentage ?? { x: 0, y: 0 },
			},
			[RobotPrimaryCamera.ZOOM_CAM]: {
				rotationDegrees: sessionInfo.capabilities.zoom_camera_rotation,
				crop: DEFAULT_ZOOM_CAM_CROPPING,
				deviation: sessionInfo.capabilities.zoom_cam_deviation_percentage ?? { x: 0, y: 0 },
				// If position is not set then set it to a robot reference capiblity
				position: sessionInfo.capabilities.zoom_cam_position ?? {
					deviation_percentage: { x: 0.4246, y: 0.2455 },
					dimension_percentage: { width: 0.2049, height: 0.2041 },
				},
			},
		};
	}, [sessionInfo.capabilities]);

	const [isSessionInitializing, setIsSessionInitializing] = useState(true);
	const isSessionStarting =
		sessionState === 'NotInitialized' ||
		(sessionState === 'InProgress' && !hasPrimaryVideoStartedPlaying);
	const isSessionRetrying = sessionState === 'Retrying';
	const isSessionPaused = sessionState === 'Paused' || sessionPaused;

	useEffect(() => {
		if (isSessionPaused) {
			showOverlay('EndOrPauseSessionConfirmation', true);
		}
	}, [isSessionPaused, sessionState, showOverlay]);
	useEffect(() => {
		if (isSessionRetrying) {
			showOverlay('SessionRetrying');
		}
	}, [isSessionRetrying, sessionState, showOverlay]);
	useEffect(() => {
		if (isSessionStarting) {
			showOverlay('SessionStarting');
		}
	}, [isSessionStarting, sessionState, showOverlay]);
	useEffect(() => {
		if (pilotMedia[PilotPrimaryCamera.LOCAL]?.error!) {
			showOverlay('LocalMediaError');
		}
		// Fallback to default in case there is no setting saved
		// (Other media devices use defaults as fallback)
		pilotMedia[PilotPrimaryCamera.LOCAL]?.media?.stream?.getTracks().forEach((mediaStreamTrack) => {
			switch (mediaStreamTrack.kind) {
				case 'video':
					const camera =
						settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
							SettingPageHeaders.AUDIOVIDEO
						].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.AUDIOVIDEO]
							.children[SettingHeaders.CAMERA].value;
					if (!camera?.name) {
						// Set current media as default video input
						settingsController.setSettingValue(SettingHeaders.CAMERA, {
							name: mediaStreamTrack.label,
							value: mediaStreamTrack.getSettings().deviceId,
						});
					}
					break;
			}
		});
	}, [pilotMedia]);

	/** True if the user can see at least a frame of the video,
	 * and the video is not obscured by any fullscreen-overlays */
	const isVideoVisible = hasPrimaryVideoStartedPlaying && currentOverlay === null;

	/** Nav controller */
	const { penalty, isDrivingImpaired } = useSessionNavController({
		speed: navSpeed,
		isPeerConnectionPaused: isSessionPaused,
		rtpReceivers: {
			primaryCam: robotMedia[RobotPrimaryCamera.WIDE_CAM]?.transceiver?.receiver,
			navCam: robotMedia[RobotPrimaryCamera.WIDE_CAM]?.transceiver?.receiver,
		},
		datachannel: dataChannels[DataChannels.NAV_DATACHANNEL],
		isVideoVisible,
		setParameter,
	});

	const onClickPauseSession = () => {
		togglePause();
	};

	const onClickUnpauseSession = () => {
		togglePause();
		let localVoiceVolume =
			settingsController.settings[SettingPageSectionHeaders.ADMIN].children[SettingPageHeaders.MISC]
				.children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.MISC].children[
				SettingHeaders.LOCAL_VOICE_VOLUME
			].value;
		dataChannels[DataChannels.CONTROL_DATACHANNEL].send(
			`${translationWithErrorHandling(intl, 'session.vol')} ${localVoiceVolume}`
		);
		dataChannels[DataChannels.CONTROL_DATACHANNEL].send(
			JSON.stringify({ type: 'change_volume', volume: localVoiceVolume })
		);
		hideOverlay();
	};

	// Starting : Fullscreen status management logic
	const onFullScreenClick = () => {
		if (fullScreenStatus) {
			closeFullScreen();
		} else {
			openFullscreen();
		}
		window.dispatchEvent(new Event('fullscreenchange'));
	};
	useEffect(() => {
		// add the fullscreen event handler
		const fullScreenChangeHandler = () => {
			if (document.fullscreenElement) {
				setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, true);
			} else {
				setParameter('fullScreenStatus', SET_FULL_SCREEN_STATUS, false);
			}
		};
		document.addEventListener('fullscreenchange', fullScreenChangeHandler);

		return () => {
			document.removeEventListener('fullscreenchange', fullScreenChangeHandler);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// End : Fullscreen status management logic

	// Closing event warning
	const sessionStateRef = useRef<typeof sessionState>(sessionState);
	useMemo(() => {
		sessionStateRef.current = sessionState;
		return sessionState;
	}, [sessionState]);
	useLayoutEffect(() => {
		const closeEventHandler = (ev: BeforeUnloadEvent) => {
			if (
				sessionStateRef.current === 'InProgress' ||
				sessionStateRef.current === 'SoftRetrying' ||
				sessionStateRef.current === 'Paused' ||
				sessionStateRef.current === 'Retrying'
			) {
				setParameter('isAbruptlyClosing', SET_iS_ABRUPTLY_CLOSING, true);
				ev.preventDefault();
				setTimeout(() => {
					setTimeout(function () {
						setParameter('isAbruptlyClosing', SET_iS_ABRUPTLY_CLOSING, false);
					}, 3000);
				}, 1);
				return (ev.returnValue = translationWithErrorHandling(
					intl,
					'session.areYouSureYouWantToClose'
				));
			}
		};
		window.addEventListener('beforeunload', closeEventHandler);
		return () => {
			window.removeEventListener('beforeunload', closeEventHandler);
		};
	}, []);

	// Settings handling
	const onSettingsClick = () => {
		settingsController.toggle();
	};

	useLayoutEffect(() => {
		if (hasPrimaryVideoStartedPlaying) return;
		const primaryCamVideoTimeoutID = setTimeout(() => {
			showOverlay('SessionNetworkFailure');
			end('RETRY_FAILED');
		}, SESSION_NETWORK_FAILURE_TIMEOUT);

		return () => clearTimeout(primaryCamVideoTimeoutID);
	}, [hasPrimaryVideoStartedPlaying, showOverlay]);
	useLayoutEffect(() => {
		if (sessionState !== 'Retrying') return;
		const retryFailedTimeoutID = setTimeout(() => {
			showOverlay('SessionNetworkFailure');
			end('RETRY_FAILED');
		}, SESSION_NETWORK_FAILURE_TIMEOUT);

		return () => clearTimeout(retryFailedTimeoutID);
	}, [sessionState, showOverlay]);
	const onPrimaryVideoPlaybackToggle = (value: boolean) => {
		setHasPrimaryVideoStartedPlaying(value);
		if (value && !isSessionPaused) {
			setIsSessionInitializing(false);
			hideOverlay();
		}
	};

	const isJoystickControlSupportedByRobot = true;

	const renderNavViewWithSessionOptions = (args: { isJoystickMounted: boolean }) => {
		return (
			<NavViewWithSessionOptions
				// * SessionOptions props
				onClickHangUp={() => showOverlay('EndOrPauseSessionConfirmation')}
				localStream={pilotMedia[PilotPrimaryCamera.LOCAL]?.media?.stream!}
				hasPrimaryVideoStartedPlaying={robotMedia[RobotPrimaryCamera.WIDE_CAM]?.streams[0]?.active}
				// * NavigationVideo props
				mediaStream={robotMedia[RobotNavCamera.NAV_CAM]?.streams[0]}
				isJoystickMounted={args.isJoystickMounted}
				isDrivingImpaired={isDrivingImpaired}
				penalty={penalty}
				// * extra props
				isStreamSynced={isStreamSynced}
				navCameraRotation={sessionInfo.capabilities.nav_camera_rotation}
				navCameraDeviationPercentage={sessionInfo.capabilities.nav_cam_deviation_percentage}
				sessionState={sessionState}
			/>
		);
	};

	useEffect(() => {
		start();
	}, []);

	useMemo(() => {
		if (reservationSessionEnded) end('RESERVATION_ENDED');
		return reservationSessionEnded;
	}, [end, reservationSessionEnded]);

	// Use Cameras calibration tool
	const isCameraCalibrationToolEnabled = useMemo(
		() =>
			settingsController.settings[SettingPageSectionHeaders.ADMIN].children[
				SettingPageHeaders.TOOLS
			].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.MISC].children[
				SettingHeaders.ENABLE_CAMERA_CALIBRATION_TOOL
			].value,
		[settingsController.settings]
	);
	useMemo(() => {
		// TODO: Move x and y to a setting on the same level of enable camera calibration tool setting
		const x = null,
			y = null;
		setParameter(
			'cameraCalibrationToolEnabled',
			SET_CAMERA_CALIBRATION_TOOL_ENABLED,
			isCameraCalibrationToolEnabled
		);
		if (x && y)
			setParameter('cameraCalibrationDeviationStep', SET_CAMERA_CALIBRATION_DEVIATION_STEP, {
				x,
				y,
			});
		return isCameraCalibrationToolEnabled;
	}, [isCameraCalibrationToolEnabled]);

	// Use drag mode
	const dragMode = useMemo(
		() =>
			settingsController.settings[SettingPageSectionHeaders.EXPERIMENTAL].children[
				SettingPageHeaders.APPEARANCE
			].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.MISC].children[
				SettingHeaders.DRAG_MODE
			].value,
		[settingsController.settings]
	);
	useMemo(() => {
		setParameter('dragMode', SET_DRAG_MODE, dragMode);
		return dragMode;
	}, [dragMode]);

	const [showSettings, setShowSettings] = useState<boolean>(true);
	(window as any).toggleSettings = () => setShowSettings((prev) => !prev);

	const showUrlShare = useRef(true);
	useEffect(() => {
		showUrlShare.current =
			settingsController.settings.Experimental.children[SettingPageHeaders.URL_SHARING].children[
				SettingTabHeaders.GENERAL
			].children[SettingSectionHeaders.URL_SHARING].children[
				SettingHeaders.URL_SHARING_ENABLED
			].value;
	}, [settingsController.settings]);

	const navSpeedSetting = useMemo(
		() =>
			settingsController.settings[SettingPageSectionHeaders.ADMIN].children[SettingPageHeaders.MISC]
				.children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.MISC].children[
				SettingHeaders.NAV_SPEED
			].value,
		[settingsController.settings]
	);
	useEffect(() => {
		setParameter('navSpeed', SET_NAV_SPEED, navSpeedSetting);
		return navSpeedSetting;
	}, [navSpeedSetting]);

	useEffect(() => {}, [(window as any).webGlCrashed]);

	const [digitalZoomSetting, setDigitalZoomSetting] = useState<boolean>(
		settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS]?.children[
			SettingPageHeaders.AUGMENTED_REALITY
		]?.children[SettingTabHeaders.GENERAL]?.children[SettingSectionHeaders.AUGMENTED_REALITY]
			?.children[SettingHeaders.ENABLE_DIGITAL_ZOOM]?.value
	);
	useEffect(() => {
		setDigitalZoomSetting(
			settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS]?.children[
				SettingPageHeaders.AUGMENTED_REALITY
			]?.children[SettingTabHeaders.GENERAL]?.children[SettingSectionHeaders.AUGMENTED_REALITY]
				?.children[SettingHeaders.ENABLE_DIGITAL_ZOOM]?.value
		);
	}, [settingsController.settings]);

	const [superZoomSetting, setSuperZoomSetting] = useState<boolean>(
		settingsController.settings[SettingPageSectionHeaders.EXPERIMENTAL]?.children[
			SettingPageHeaders.ZOOM
		]?.children[SettingTabHeaders.GENERAL]?.children[SettingSectionHeaders.SUPERZOOM]?.children[
			SettingHeaders.ENABLE_SUPER_ZOOM
		]?.value
	);
	useEffect(() => {
		setSuperZoomSetting(
			settingsController.settings[SettingPageSectionHeaders.EXPERIMENTAL]?.children[
				SettingPageHeaders.ZOOM
			]?.children[SettingTabHeaders.GENERAL]?.children[SettingSectionHeaders.SUPERZOOM]?.children[
				SettingHeaders.ENABLE_SUPER_ZOOM
			]?.value
		);
	}, [settingsController.settings]);

	return (
		<div
			className="Session"
			id="Session"
			data-session-id={sessionInfo.uuid}
			// FIXME: We must remove any sensitive data from sessionInfo, before adding it as a data-attribute
			// data-session-info={signalingClient.sessionInfo}
		>
			{reservationSessionEnded ? (
				<EndSession reservation={sessionInfo.reservation} reportUserRating={reportUserRating} />
			) : (
				<>
					<PauseOrEndSessionOverlay
						isVisible={currentOverlay === 'EndOrPauseSessionConfirmation'}
						isSessionPaused={isSessionPaused}
						onClickResumeSession={onClickUnpauseSession}
						onClickEndSession={() => closeSession('LOCAL_HANGUP')}
						onClickPauseSession={onClickPauseSession}
						onClickCancel={hideOverlay}
					/>

					<StartingSessionOverlay
						isVisible={currentOverlay === 'SessionStarting'}
						isSessionStarting={isSessionStarting}
						isSessionInitializing={isSessionInitializing}
						robot={sessionInfo.robot}
						onClickEndSession={() => closeSession('USER_EARLY_CLOSE')}
					/>

					<RetryingSessionOverlay
						isVisible={currentOverlay === 'SessionRetrying'}
						isSessionStarting={isSessionStarting}
						isSessionInitializing={isSessionInitializing}
						robot={sessionInfo.robot}
						onClickEndSession={() => closeSession('RETRY_FAILED')}
					/>

					<RobotUnavailableOverlay
						isVisible={currentOverlay === 'UnavailableRobot' || currentOverlay === 'NoRemoteVideo'}
						closeSession={() => closeSession('ROBOT_UNAVAILABLE')}
						onClickTryAgain={() => closeSession('ROBOT_UNAVAILABLE')} // TODO: Implement this, and allow user to call robot again
					/>
					<SessionNetworkFailureOverlay
						isVisible={currentOverlay === 'SessionNetworkFailure'}
						closeSession={() => closeSession('NETWORK_FAILURE')}
						robotName={sessionInfo.robot.name}
					/>

					<MediaAccessErrorOverlay
						isVisible={currentOverlay === 'LocalMediaError'}
						onEndSession={() => closeSession('MEDIA_ACCESS_ERROR')}
						robotName={sessionInfo.robot.name}
						error={pilotMedia[PilotPrimaryCamera.LOCAL]?.error!}
					/>

					<KeyboardNavInput
						disabled={
							!(
								currentOverlay === null &&
								primaryCameraState.currentPrimaryCamera === 'wide_cam' &&
								!pivotState.currentPivot
							)
						}
					>
						<div className="sessionInfoContainer">
							<div className="sessionInfoContainerUpper">
								<div className="optionContainer">
									<div className="optionButton" onClick={onFullScreenClick}>
										<img
											className="icon"
											alt=""
											src={fullScreenStatus ? exitFullscreenIcon : goFullscreenIcon}
										/>
									</div>
									{showSettings ? (
										<div className="optionButton" onClick={onSettingsClick}>
											<img className="icon" alt="" src={settingsIcon} />
										</div>
									) : null}
								</div>
							</div>
							<div className="sessionInfoContainerLower">
								{(window as any).webGlCrashed && (
									<Snackbar
										icon={genericAlertIcon}
										iconWrapper={genericAlertIcon}
										title={translationWithErrorHandling(intl, 'session.drivingLinesDisabled')}
										actionRequired={translationWithErrorHandling(
											intl,
											'session.devicePerformanceIssueDetected'
										)}
									/>
								)}
								<FeedbackNotificationBar />
								<PeerConnectionStatusIndicator stats={stats} />
								<ActiveNavigationInputIndicator
									isDrivingImpaired={isDrivingImpaired}
									dockControllerStatus={dockControllerStatus}
									reconnecting={sessionState === 'SoftRetrying'}
								/>
								<ImpairedDrivingIndicator
									isDrivingImpaired={isDrivingImpaired}
									penalty={penalty}
									dockControllerStatus={dockControllerStatus}
								/>
								{autoParkEnabled ? (
									<AutoDockingInput
										navDataChannel={dataChannels[DataChannels.NAV_DATACHANNEL]}
										isPeerConnectionPaused={isSessionPaused}
										isVideoVisible={isVideoVisible}
										reportWebRTCEvent={reportWebRTCEvent}
									/>
								) : null}
								<RobotName name={sessionInfo.robot.name} />
							</div>
						</div>
						{showUrlShare.current && (
							<div className="urlShareDragSection">
								<UrlShare
									shareUrl={shareUrl}
									robotStatus={robotStatus ?? sessionInfo.robotStatus!}
									// controlDataChannel={dataChannels[DataChannels.CONTROL_DATACHANNEL]}
									sessionState={sessionState}
								/>
							</div>
						)}
						<div className="localVideoDragSection">
							<LocalVideo
								robotStatus={robotStatus ?? sessionInfo.robotStatus!}
								connectionsQuality={connectionsQuality}
								startWideCameraStats={() => undefined}
								stopWideCameraStats={() => undefined}
								wideCameraStats=""
								isGreyedOut={false}
								isPaused={false}
								shouldShowLoadingIndicator={
									!isStreamSynced ||
									!hasPrimaryVideoStartedPlaying ||
									!pilotMedia[PilotPrimaryCamera.LOCAL]?.media?.stream?.active ||
									isSessionRetrying ||
									isSessionPaused
								}
								media={pilotMedia[PilotPrimaryCamera.LOCAL]}
								primaryMedia={robotMedia[RobotPrimaryCamera.WIDE_CAM]?.streams[0]}
								primaryRotation={sessionInfo.capabilities.wide_camera_rotation}
								navMedia={robotMedia[RobotNavCamera.NAV_CAM]?.streams[0]}
								navRotation={sessionInfo.capabilities.nav_camera_rotation}
								robotLocalCamerasSupported={sessionInfo.capabilities.robotLocalCameras!}
								setLocalDisplays={setLocalDisplays}
								primaryCameraState={primaryCameraState}
								sessionState={sessionState}
								stats={stats}
								sessionId={sessionInfo.uuid}
							/>
						</div>
						<RemotePrimaryCamVideo
							primaryCameraState={primaryCameraState}
							pivotState={pivotState}
							sessionState={sessionState}
							onPlaybackToggle={onPrimaryVideoPlaybackToggle}
							mediaStream={robotMedia[RobotPrimaryCamera.WIDE_CAM]?.streams[0]}
							cameraConfigs={primaryCamerasConfig}
							canShowLoadingIndicator
							isStreamSynced={isStreamSynced}
							isDigitalZoomEnabled={digitalZoomSetting}
							isSuperZoomEnabled={superZoomSetting}
							isSuperZoomPivotEnabled={sessionInfo.capabilities.super_zoom_pivot!}
							toggleSuperZoom={toggleSuperZoom}
							pivot={pivot}
						/>
						{sessionInfo.reservation &&
						!reservationSessionEnded &&
						dataChannels[DataChannels.CONTROL_DATACHANNEL] &&
						dataChannels[DataChannels.CONTROL_DATACHANNEL].readyState === 'open' ? (
							<SessionEndWarning
								reservation={sessionInfo.reservation}
								setReservationSessionEnded={setReservationSessionEnded}
								onHangupTimeout={onHangupTimeout}
							/>
						) : null}

						{/* If the robot does not support joystick control, we render the nav-view within the keyboard input.
					This makes it possible to the pilot to be able to control the robot with keyboard
					even when the mouse cursor is on the nav-view. */}
						{renderNavViewWithSessionOptions({
							isJoystickMounted: isJoystickControlSupportedByRobot
								? primaryCameraState.currentPrimaryCamera === 'wide_cam' && !pivotState.currentPivot
								: false,
						})}
					</KeyboardNavInput>
				</>
			)}
		</div>
	);
};

export default React.memo(reduxConnector(Session));
